#SeatAdd {
  .section {
    margin-bottom: 20px;
    border: 2px dashed #b39ddb;
    border-radius: 5px;
    padding: 12px;
  }
  .flex-tween {
    margin: 8px 0;
    gap: 16px;
  }
  .rel {
    flex: 1;
  }
  .grid-container {
    overflow: scroll;
  }
}
