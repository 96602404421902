/* 미디어쿼리 */
@import "./mediaQuery";
@import "./mixin";

/* 노토산스 */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@500&display=swap");
/* 담미온 */
@import url("https://fonts.googleapis.com/css2?family=Damion&display=swap");
/* Montserrat */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap");

/* 숨긴콘텐츠 */
.a11y-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  /* position이 absolute일때만 동작, 구형 browser에서 사용 */
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
}
/* 본문 기본설정 */
html {
  font-size: 16px;

  scroll-behavior: smooth;
}

table {
  td {
    max-width: 350px;
  }
}

.flex-center {
  @include Flex();
  flex-flow: wrap;
}

.flex-start {
  @include Flex("flex-start");
  flex-flow: wrap;
}
// .swagger-container {
//   position: fixed;
//   top: 100px;
//   left: 100px;
//   width: 400px;
//   height: 400px;
//   background: #fff;
//   border: 1px solid #000;
//   border-radius: 5px;
//   z-index: 9999;
// }

.logo {
  .rel {
    display: flex;
    img {
      margin-left: 8px;
      cursor: pointer;
    }
  }
}
.ico30 {
  width: 30px;
  height: 30px;
}

.rel {
  position: relative;
  .formik-input {
    width: 100%;
  }
}

.abs {
  position: absolute;
  top: 0;
  &.r {
    right: 0;
  }
  &.l {
    left: 0;
  }
}

.formik-input {
  font-size: 1.2em;
  padding: 8px;
}

.flex-tween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

pre code {
  background: rgb(24, 24, 32);
  white-space: pre;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  display: block;
  max-width: 100%;
  min-width: 100px;
  font-size: 16px;
  padding: 15px 20px 12px 22px;
  line-height: 1.75;
  color: #f5d67b;
}

.list-form {
  ul {
    li {
      width: 100%;
      padding: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      label {
        margin-right: 8px;
        flex: 1;
      }
      input {
        border: 1px solid #000;
        padding: 4px 8px;
        min-width: 250px;
        flex: 4;
      }
    }
  }
}

$transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);

.collapse-css-transition {
  position: relative;
  transition: height $transition;

  &:after {
    transition: opacity $transition;
    pointer-events: none;
    opacity: 0;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(rgba(white, 0) 20%, rgba(white, 0.8));
  }

  &.--c-collapsed {
    &:after {
      opacity: 1;
    }
  }
}

.m8 {
  margin: 8px;
}

.ml8 {
  margin-left: 8px;
}
.mr8 {
  margin-right: 8px;
}
.mt8 {
  margin-top: 8px;
}

.mb8 {
  margin-bottom: 8px;
}

.pt8 {
  padding-top: 8px;
}
.pb8 {
  padding-bottom: 8px;
}

.btn {
  min-width: 80px;
  border: 1px solid #000;
  height: 40px;
  padding: 8px;
  background: #fff;
  border-radius: 4px;
}

.btn_toggle:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../imgs/faq_arr.png) center center no-repeat;
  transform: rotate(0deg);
  background-size: 30px 30px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-left: 5px;
  transition: 0.3s;
}

.btn_toggle.on:after {
  transform: rotate(180deg);
}

/* 스타일 초기화*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

body {
  margin: 0;
  font-family: "Noto Sans KR", sans-serif;
  background-color: #fff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrap {
  width: 100%;
}
.MuiToolbar-root {
  background-color: #636190;
}
.MuiPaper-root.MuiDrawer-paper {
  background-color: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 500;
}
a {
  color: inherit;
  text-decoration: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* ul 초기화 */
ul {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
}
/* button 초기화 */
button {
  padding: 0;
  cursor: pointer;
  border: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Montserrat", "Noto Sans kr", sans-serif;
}
input {
  border: none;
  font-family: "Montserrat", "Noto Sans kr", sans-serif;
  font-weight: 500;
}
/* table 초기화 */
table {
  border-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
/* 높이 초기화 */
.init-height {
  min-height: calc(100vh - 264px);
}

#error-input-border {
  padding-bottom: 20px;
  height: 50px;
  border: 2px rgb(255, 91, 91) solid;
  animation: shivering 0.22s alternate cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

#error-border {
  .tox.tox-tinymce {
    padding-bottom: 20px;
    border: 2px rgb(255, 91, 91) solid;
    animation: shivering 0.22s alternate cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}

@keyframes shivering {
  0% {
    transform: translate3d(0, 0, 0);
  }

  20% {
    transform: translate3d(-0.8%, 0, 0);
  }

  40% {
    transform: translate3d(0.8%, 0, 0);
  }

  60% {
    transform: translate3d(-0.8%, 0, 0);
  }

  80% {
    transform: translate3d(0.8%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.formik-error-message {
  position: absolute;
  bottom: 2px;
  right: 8px;
  display: none;
  align-items: center;
  color: rgb(255, 91, 91);
  height: 2rem;
  justify-content: start;
  transform: translate3d(5%, 0, 0);
  font-size: 12px;
  &#error-on {
    display: flex;
    transform: translateY(6px);
  }
  &#mui-error-on {
    display: flex;
    right: unset;
  }
}

@include mobile {
  .formik-error-message {
    display: none;
    align-items: center;
    color: rgb(255, 91, 91);
    height: 2rem;
    justify-content: start;
    transform: translate3d(5%, 0, 0);
    font-size: 12px;
    &#error-on {
      display: flex;
      transform: translateY(6px);
    }
  }
}

@include tablet {
  .formik-error-message {
    position: absolute;
    display: none;
    align-items: center;
    color: rgb(255, 91, 91);
    height: 2rem;
    justify-content: start;
    transform: translate3d(5%, 0, 0);
    font-size: 12px;
    &#error-on {
      display: flex;
      transform: translateY(6px);
    }
  }
}

.loading-wrap {
  @include Flex();
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  opacity: 0.4;
  top: 0;
  z-index: 999;
}

@include mobile {
  html {
    font-size: 13px;
  }
}

@include tablet {
  html {
    font-size: 14px;
  }
}
