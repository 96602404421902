$primaryColor: #fff;
$borderColor: #2eb9ae;
$isActiveColor: #adffab;
$formBgColor: #4874ad;
$errorColor: rgb(255, 110, 122);

@mixin BgSet($path, $size, $pos: center) {
  background: url($path);
  background-size: $size;
  background-position: $pos;
  background-repeat: no-repeat;
}

@mixin Flex($justify: center, $align: center) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin VirtualFill($pos: absolute) {
  content: "";
  position: $pos;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin Size($width: 100%, $height: 100%) {
  width: $width;
  height: $height;
}

.signin-body {
  color: $primaryColor;
  overflow: hidden;
  @include Size(100%, calc(100vh - 104px));
  @include BgSet("../../imgs/main_02r.png", cover, center);
  @include Flex();
  backdrop-filter: blur(2px);
  @keyframes effectRightMove {
    0% {
    }

    100% {
      left: 100%;
    }
  }

  @keyframes effectLeftMove {
    0% {
    }

    100% {
      right: 100%;
    }
  }

  @keyframes effectUpMove {
    0% {
    }

    100% {
      top: 100%;
    }
  }

  @keyframes effectDownMove {
    0% {
    }

    100% {
      bottom: 100%;
    }
  }

  .effect.horizontal,
  .effect.vertical {
    position: absolute;
    width: 200px;
    height: 5px;
  }

  .effect.vertical {
    width: 5px;
    height: 100px;
    background: #9575cd;
  }

  .effect.horizontal:nth-child(odd) {
    animation: effectRightMove 2s ease-in-out forwards;
    background: linear-gradient(45deg, transparent, #9575cd);
  }

  .effect.horizontal:nth-child(even) {
    animation: effectLeftMove 2s ease-in-out forwards;
    background: linear-gradient(-45deg, transparent, #9575cd);
  }

  .effect.vertical:nth-child(odd) {
    animation: effectUpMove 2s ease-in-out forwards;
    background: linear-gradient(45deg, #9575cd, transparent);
  }

  .effect.vertical:nth-child(even) {
    animation: effectDownMove 2s ease-in-out forwards;
    background: linear-gradient(45deg, transparent, #9575cd);
  }

  .effect:nth-child(1),
  .effect:nth-child(5) {
    top: 0;
    left: 0;
  }

  .effect:nth-child(2),
  .effect:nth-child(7) {
    top: 0;
    right: 0;
  }

  .effect:nth-child(3),
  .effect:nth-child(6) {
    bottom: 0;
    left: 0;
  }

  .effect:nth-child(4),
  .effect:nth-child(8) {
    bottom: 0;
    right: 0;
  }

  .book {
    position: absolute;
    top: 0;
    @include Size(400px, 600px);
    padding: 15px;
    background: linear-gradient(#212121 60%, #263238 40%);
    transform: rotate(-37.5deg) skewX(10deg) translate(-300px, -50px);
    box-shadow: -35px 35px 50px rgba(0, 0, 0, 1);
    transition: 0.5s;

    &::before {
      content: "";
      padding-left: 5px;
      padding-top: 20px;
      overflow: hidden;
      @include Size(25px, 100%);
      background: linear-gradient(#212121 60%, #263238 40%);
      position: absolute;
      top: -3px;
      left: 7px;
      transform: skewY(-45deg) translate(-30px, -15px);
      box-shadow: inset -10px 0 20px rgba(0, 0, 0, 0.2);
    }

    &::after {
      content: "";
      @include Size(98%, 25px);
      background: rgb(255, 255, 255);
      position: absolute;
      bottom: 5px;
      left: 5px;
      transform: skewX(-45deg) translate(15px, 30px);
      box-shadow: inset -10px 0 20px rgba(0, 0, 0, 0.2);
    }

    .cover {
      background: url(../../imgs/main.svg);
      background-size: contain;
      background-attachment: fixed;
      background-repeat: no-repeat;
      @include Size(100%, 65%);
    }

    h2 {
      position: absolute;
      bottom: -70px;
      left: 10px;
      font-size: 2em;
      line-height: 1em;
    }
  }

  .signin-container {
    border: 1px solid $primaryColor;
    border-radius: 15px;
    overflow: hidden;
    @include Flex(space-between);
    @include Size(90%, 70%);

    &::before {
      @include VirtualFill(fixed);
      backdrop-filter: blur(2px);
      z-index: -1;
      box-shadow: inset 0px 0px 500px rgba(0, 0, 0, 0.4);
    }

    .imgBx {
      @include Size(60%);
      background: #303030;
      // @include BgSet("../../imgs/main.svg", contain, center);
    }

    @keyframes BgPosMove {
      0% {
        background-position-x: 0;
      }

      100% {
        background-position-y: 50000px;
        background-position-x: 20000px;
      }
    }

    .mainBx {
      @include Size(40%);
      // background: $formBgColor;
      background: radial-gradient(
        circle at 30%,
        #fffde9 1%,
        #563c832f 1%,
        #aaa6d12f 1%,
        #424242 25%
      );
      animation: BgPosMove 80s linear infinite;
      padding: 20px;

      form {
        h1 {
          color: $primaryColor;
          display: flex;
          position: relative;
          align-items: center;
          text-transform: uppercase;

          &::before,
          &::after {
            content: "";
            border-bottom: solid $primaryColor;
            border-width: 0.05em 0;
            height: 0.1em;
            margin: 0.2em;
            flex: 1;
          }

          &::after {
            flex: 10;
          }
        }

        input {
          position: relative;
          display: block;
          width: 100%;
          margin: 25px 0;
        }

        p {
          color: $errorColor;
          margin-top: -15px;
        }

        .btn-group {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          button {
            &:first-child,
            &:hover {
              background: rgb(30, 55, 92);
              transition: 0.4s;
            }

            all: unset;
            cursor: pointer;
            padding: 5px 8px;
            min-width: 50px;
            height: 25px;
            text-align: center;
            margin: 6px;
            font-size: 0.8em;
            border: 1px solid $primaryColor;
          }
        }
      }

      .decoration {
        h2 {
          color: $primaryColor;
          position: relative;
          margin: 8px 0;
          &::before {
            content: "";
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 1px;
            background: $borderColor;
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .signin-body {
    .book {
      transform: rotate(0) translate(100px, 150px);
      left: 0;
    }
    .signin-container {
      .imgBx {
        @include Size(450px);
      }
      .mainBx {
        @include Size(calc(100% - 450px));
      }
    }
  }
}

@media (max-width: 1200px) {
  .signin-body {
    .book {
      display: none;
    }
    .signin-container {
      .imgBx {
        display: none;
      }
      .mainBx {
        flex: 1;
      }
    }
  }
}

@media (max-width: 880px) {
  .signin-body {
    .signin-container {
      .imgBx {
        display: none;
      }

      .mainBx {
        flex: 1;

        form {
          .btn-group {
            flex-flow: column;
            justify-content: stretch;
            align-items: flex-start;

            button {
              margin: 7px 0;
            }
          }
        }

        .decoration {
          font-size: 0.85em;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .signin-body {
    .signin-container {
      @include Size(90%, 60%);

      .mainBx {
        .decoration {
          display: none;
        }
      }
    }
  }
}
